<script setup>
import Banner from "~/components/layout/Default/HomeMain/Banner.vue";
import Download from "~/components/layout/Default/HomeMain/Download.vue";
import AboutUs from "~/components/layout/Default/HomeMain/AboutUs.vue";
// import Serve from "~/components/layout/Default/HomeMain/Serve.vue";
// import Status from "~/components/layout/Default/HomeMain/Status.vue";
// import Service from "~/components/layout/Default/HomeMain/Service.vue";
// import GoldCard from "~/components/layout/Default/HomeMain/GoldCard.vue";
// import Benefits from "~/components/layout/Default/HomeMain/Benefits.vue";
// import Features from "~/components/layout/Default/HomeMain/Features.vue";
// import TailoredService from "~/components/layout/Default/HomeMain/TailoredService.vue";
import DefaultFooter from "~/components/layout/Default/Footer/DefaultFooter.vue";

const Service = defineAsyncComponent(() => import("~/components/layout/Default/HomeMain/Service.vue"));
const Serve = defineAsyncComponent(() => import("~/components/layout/Default/HomeMain/Serve.vue"));
const Status = defineAsyncComponent(() => import("~/components/layout/Default/HomeMain/Status.vue"));
const GoldCard = defineAsyncComponent(() => import("~/components/layout/Default/HomeMain/GoldCard.vue"));
const Benefits = defineAsyncComponent(() => import("~/components/layout/Default/HomeMain/Benefits.vue"));
const Features = defineAsyncComponent(() => import("~/components/layout/Default/HomeMain/Features.vue"));
const TailoredService = defineAsyncComponent(() => import("~/components/layout/Default/HomeMain/TailoredService.vue"));


useHead({
  title: "Golden Suisse",
  meta: [{ name: "description", content: `You are viewing the Golden Suisse` }],
});
</script>

<template>
  <div>
    <Banner />
    <Download />
    <AboutUs />
    <Service />
    <Serve />
    <Status />
    <GoldCard />
    <Benefits />
    <Features />
    <TailoredService />
    <DefaultFooter />
  </div>
</template>